import Config from 'lib/config';
import dataProvider from 'lib/dataProvider';

export const endpoint = 'pay/installments'; // /api/pay/installments

console.log('pncra-installments: App.js Config', Config);
const api = (window.api = dataProvider(Config));
console.log('pncra-installments: App.js api', api);

export const getInstallments = (window.getInstallments = () => {
  return new Promise((resolve, reject) => {
    api
      .get(`dash/public/${endpoint}`)
      .then((resp) => {
        console.log('pncra-installments app: getInstallments', {
          resp,
        });
        resolve(resp?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
});
export const setInstallments = (window.setInstallments = async ({
  account,
  invoice,
  debt,
  duedate_for_fulfillment,
  duedate_for_installments,
  installments,
}) => {
  console.log('pncra-installments app: setInstallments', {
    account,
    invoice,
    debt,
    duedate_for_fulfillment,
    duedate_for_installments,
    installments,
  });

  return api.post(endpoint, {
    account,
    invoice,
    debt,
    duedate_for_fulfillment,
    duedate_for_installments,
    installments,
  });
});
