const dayjs = require('dayjs');
const d = (s) => dayjs(s);
const ds = (s) => d(s).format('YYYY-MM-DD');
const m = (s) => parseFloat(parseFloat(s).toFixed(2));
const today = dayjs();

const installments_day = 10;
const installments_month = 11;

const paymentPlan = ({
  account,
  invoice,
  debt,
  duedate,
  existingMandate,
  existingMandatePayments = 0,
}) => {
  let goal = {
    amount_to_pay: m(debt),
    duedate_for_fulfillment: `${d(duedate).format('YYYY-MM-DD')}`,
    duedate_for_installments: `${d(duedate).format(
      'YYYY'
    )}-${installments_month}-${installments_day}`,
  };

  // whichever comes first
  goal.duedate =
    goal.duedate_for_fulfillment > goal.duedate_for_installments
      ? goal.duedate_for_installments
      : goal.duedate_for_fulfillment;

  const months =
    Math.floor(today.diff(goal.duedate_for_installments, 'days') / 28) * -1;

  let installments_amount = m(goal.amount_to_pay / months);
  let installments_total = m(months * installments_amount);
  let first_month_correction = m(m(goal.amount_to_pay) - m(installments_total));
  const payment_number_offset = parseInt(existingMandatePayments || 0);

  let installments = Array.apply(null, Array(months)).map((p, i) => ({
    i: i + 1 + payment_number_offset,
    name: `Payment ${i + 1 + payment_number_offset}`,
    date:
      i === 0 && !existingMandate
        ? ds(today)
        : ds(
            today
              .add(i, 'month')
              .startOf('month')
              .add(installments_day - 1, 'days')
          ),
    amount: m(installments_amount + (i === 0 ? first_month_correction : 0)),
  }));

  const plan = {
    account,
    invoice,
    debt: m(debt),
    duedate: goal.duedate,
    duedate_for_fulfillment: goal.duedate_for_fulfillment,
    duedate_for_installments: goal.duedate_for_installments, // ignore invoice's actual duedate
    first_month_correction,
    number_of_installments: months,
    installments_amount,
    installments,
    first_payment: installments[0],
    other_payments: installments[1],
    existingMandate,
    existingMandatePayments,
    payment_number_offset,
  };

  return plan;
};

/*
const plan = paymentPlan({
  invoice: '450451',
  account: '186704',
  debt: '76.16',
  duedate: '2022-12-17',
});

plan;
*/

module.exports.paymentPlan = paymentPlan;
