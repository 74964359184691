import React, { useState } from 'react';
import dayjs from 'lib/helpers/daysjs';

import { setInstallments } from 'lib/installments';
import { paymentPlan } from 'lib/paymentPlan';

import { Box } from '@mui/system';
import { PNButton } from 'components/PNButton';
import { dev } from 'lib/config';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const c = (number) =>
  new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  }).format(number);
const d = (date) => dayjs(date).format('MMM Do');

export const App = ({
  account,
  invoice,
  debt,
  duedate,
  ['existing-mandate']: existingMandate,
  ['existing-mandate-payments']: existingMandatePayments,
  ...args
}) => {
  const [error, setError] = useState(false);
  const [working, setWorking] = useState(false);
  const [updated, setUpdated] = useState(false);
  if (!(account && invoice && debt && duedate)) {
    console.error(
      'pncra-installments app: Missing required information to create payment plan',
      {
        account,
        invoice,
        debt,
        duedate,
        existingMandate,
        existingMandatePayments,
        args,
      }
    );
    return null;
  }
  console.log('pncra-installments app: start with props', {
    account,
    invoice,
    debt,
    duedate,
    existingMandate,
    existingMandatePayments,
  });
  const plan = paymentPlan({
    account,
    invoice,
    debt,
    duedate,
    existingMandate,
    existingMandatePayments,
  });
  console.log('pncra-installments app: start with plan', { plan });

  const handleClickPay = (window.handleClickPay = () => {
    console.log('pncra-installments app: handleClickPay invoked', {
      plan,
    });
    setError('');
    setWorking(true);
    setInstallments(plan)
      .then((resp) => {
        console.log(
          'pncra-installments app: payment plan configured successfully',
          { resp, plan }
        );
        const data = resp.data || {};
        let new_error = '';
        const { redirect, total, balance } = data || {};

        if (balance !== 0) new_error = 'incorrect balance';
        if (!existingMandate && !redirect) new_error = 'missing redirect url';

        if (new_error) {
          setError(new_error);
          console.error('pncra-installments app: payment plan failed', {
            error: new_error,
            data,
            redirect,
            total,
            balance,
            plan,
          });
          return;
        }

        if (existingMandate) {
          setUpdated(true);
        } else {
          if (dev) {
            window.open(redirect);
          } else {
            top.location = redirect;
          }
        }
      })
      .catch((err) => {
        console.log('pncra-installments app: payment plan failed', {
          err,
          plan,
        });
      })
      .finally(() => setWorking(false));
  });
  console.log('pncra-installments app: handleClickPay installed', {
    handleClickPay,
  });

  return (
    <Box sx={{ fontSize: 20, textAlign: 'center', width: '100%' }}>
      <Box sx={{}}>
        <img src="https://res.cloudinary.com/xmas/image/upload/q_auto,f_auto,w_120/logo/pines_and_needles_logo.png?2020" />
      </Box>
      <Box sx={{ marginTop: 2 }}>
        <>
          Total due {c(plan.debt)} by {d(plan.duedate_for_installments)}
        </>
      </Box>
      {existingMandate ? (
        <></>
      ) : (
        <>
          <Box sx={{ marginTop: 4 }}>
            <>Pay {c(plan.first_payment.amount)} now</>
          </Box>
          <Box sx={{ marginTop: 2 }}>
            {working ? (
              <>Please wait...</>
            ) : (
              <PNButton onClick={handleClickPay} disabled={working}>
                Agree &amp; Pay
              </PNButton>
            )}
          </Box>
        </>
      )}
      <Box sx={{ fontSize: 14, marginTop: 6 }}>
        {existingMandate ? (
          <>
            Pay in {plan.installments.length} installments:
            {/* {c(plan.other_payments.amount)} monthly */}
          </>
        ) : (
          <>
            Then {plan.installments.length - 1} installments:
            {/* {c(plan.other_payments.amount)} monthly */}
          </>
        )}
        <TableContainer
          component={Paper}
          sx={{ fontSize: 14, marginTop: 1, maxWidth: 280, margin: '0 auto' }}
        >
          <Table aria-label="Payment table">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell align="right">Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {plan.installments.slice(existingMandate ? 0 : 1).map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {d(row.date)}
                  </TableCell>
                  <TableCell align="right">{c(row.amount)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {existingMandate ? (
          updated ? (
            <>
              <Box sx={{ marginTop: 2 }}>Payment Plan Updated ✅</Box>
              <Box sx={{ marginTop: 2 }}>
                All done. You can close this window
              </Box>
            </>
          ) : (
            <>
              <Box sx={{ marginTop: 2 }}>
                {working ? (
                  <>Please wait...</>
                ) : (
                  <PNButton onClick={handleClickPay} disabled={working}>
                    Agree &amp; Update Plan
                  </PNButton>
                )}
              </Box>
            </>
          )
        ) : (
          <></>
        )}

        {!error ? (
          <></>
        ) : (
          <Box sx={{ marginTop: 3, color: 'red' }}>⚠️ Error: {error}</Box>
        )}
      </Box>
    </Box>
  );
};

export default App;
