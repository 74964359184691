import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const Render = (element, options = {}) => {
  console.log('pncra-installments AppRender', {
    element,
    options,
    App,
  });
  if (!element) return;

  console.log('subscr app', { element, options });

  ReactDOM.render(
    <React.StrictMode>
      <App {...options} />
    </React.StrictMode>,
    element
  );
};

if (window.fwx) {
  window.fwx.app.install('pncra-installments', Render);
} else {
  Render(document.getElementById('root'));
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
