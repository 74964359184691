import { ParseContext, EnvironmentConstants } from './context';
const context = ParseContext(process.env);
const constants = EnvironmentConstants(process.env);

console.log('Config stasrt', {
  env: process.env,
  constants,
  context,
  parsed: ParseContext(process.env),
});

export const hostname = window.location.hostname;
export const domain = context.domain || hostname;
export const env = process.env.NODE_ENV;
export const dev = !!(
  env.match(/^dev/gi) ||
  domain.match(/\b(dev)\b/gi) ||
  hostname.match(/^(127|192|localhost)\b/gi)
);
const www_domain = 'www.pinesandneedles.com';
const app_domain = 'app.pinesandneedles.com';
const api_domain = dev
  ? 'dev-api.pinesandneedles.com'
  : 'api.pinesandneedles.com';

export const Config = {
  ...context,
  ...{
    //analytics: 'UA-75784877-3',
    www_domain,
    api_domain,
    app_domain: 'app.pinesandneedles.com', //'kw-app.pinesandneedles.com',
    apikey: '86f79e7ed1354e0ba226f07ea3282bc646f433b1672f6d846ba426384fda138e',
    API_BASE: `https://${api_domain}/api`,
    website: `https://${www_domain}`,
    env,
    dev,
    domain,
    app_link: dev ? `http://localhost:8888/` : `https://${app_domain}/`,
  },
  // "client": "dashboard",
  // "apikey": "jwt?",
  // "domain": "domain.com"
};

console.log('Config done', {
  env,
  dev,
  process_env: process.env,
  constants,
  context,
  parsed: ParseContext(process.env),
  domain,
  Config,
});

export default Config;
