//import React from 'react';
import styled from '@emotion/styled';
import { Button } from '@mui/material';

export const PNButton = styled(Button)({
  //'-webkit-box-shadow': '0 2px 4px 0 rgb(0 0 0 / 30%)',
  //'-ms-box-shadow': '0 2px 4px 0 rgb(2.4%,31.8%,22%)',
  //'-o-box-shadow': '0 2px 4px 0 rgb(2.4%,31.8%,22%)',
  //'-moz-box-shadow': '0 2px 4px 0 rgb(2.4%,31.8%,22%)',
  //'box-shadow': '0 2px 4px 0 rgb(0 0 0 / 30%)',
  boxShadow: '0 2px 4px 0 rgb(0 0 0 / 30%)',
  transition:
    'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  background: '#065138',
  color: '#fff',
  '&:hover': {
    background: '#043927',
  },
});
